

.header {
    height: 80vh;
    background-color: rgba(0, 0, 0, 0.4);
}
.video-header {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
}
#videoHome{
    margin: auto;
    display: block;
    background-color: #111111;
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.chevron-header {
    position: absolute;
    bottom: 3em;
    left: 50%;
    margin-left: -1em;
}

.imgGruppoHover {
    transition: 1s;
    cursor: pointer;
}
.imgGruppoHover:hover {
    transition: 1s;
    transform: scale(0.95);
    opacity: 0.75;
}

.logoPartner {
    height: 100px;
    opacity: 0.75;
    transition: 0.5s;
}
.logoPartner:hover {
    opacity: 1;
    transition: 0.5s;
}

@media only screen and (max-width: 576px) {
    .imgCol {
        width: 70vw;
    }
}
@media only screen and (min-width: 576px) {
    .imgCol {
        width: 60vw;
    }
}
@media only screen and (min-width: 768px) {
    .imgCol {
        width: 50vw;
    }
}
@media only screen and (min-width: 992px) {
    .imgCol {
        width: 20vw;
    }
}
@media only screen and (min-width: 1200px) {
    .imgCol {
        width: 20vw;
    }
}
