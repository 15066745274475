

.mainnavbar {
    z-index: 9999;
}

.navbar-top {
    background-color: var(--nero);
    padding: 2em 0em;
}

.navabar-bottom {
    background-color: var(--verde);
    padding: 1.5em 0em;
}
.navabar-bottom p, .navabar-bottom a {
    font-family: "Roboto", sans-serif !important;
}
.navabar-bottom a {
    color: var(--bianco);
    transition: 0.3s;
}
.navabar-bottom a:hover {
    font-weight: bold;
    transition: 0.3s;
}

.iconHover {
    transition: 0.3s;
    cursor: pointer;
}
.iconHover:hover {
    opacity: 0.75;
    transform: scale(0.95);
    transition: 0.3s;
}
