@font-face {
    font-family: "Futura Now Headline";
    src: url("fonts/FuturaNowHeadlineLight.ttf");
    font-weight: 200;
}
@font-face {
    font-family: "Futura Now Headline";
    src: url("fonts/FuturaNowHeadlineLightItalic.ttf");
    font-style: italic;
    font-weight: 200;
}
@font-face {
    font-family: "Futura Now Headline";
    src: url("fonts/FuturaNowHeadline.ttf");
    font-weight: normal;
}
@font-face {
    font-family: "Futura Now Headline";
    src: url("fonts/FuturaNowHeadlineItalic.ttf");
    font-style: italic;
    font-weight: normal;
}
@font-face {
    font-family: "Futura Now Headline";
    src: url("fonts/FuturaNowHeadlineMedium.ttf");
    font-weight: 500;
}
@font-face {
    font-family: "Futura Now Headline";
    src: url("fonts/FuturaNowHeadlineMediumItalic.ttf");
    font-style: italic;
    font-weight: 500;
}
@font-face {
    font-family: "Futura Now Headline";
    src: url("fonts/FuturaNowHeadlineExtraBold.ttf");
    font-weight: bold;
}
@font-face {
    font-family: "Futura Now Headline";
    src: url("fonts/FuturaNowHeadlineExtraBoldItalic.ttf");
    font-style: italic;
    font-weight: bold;
}
@font-face {
    font-family: "Futura Now Headline";
    src: url("fonts/FuturaNowHeadlineExtraBlack.ttf");
    font-weight: 900;
}
@font-face {
    font-family: "Futura Now Headline";
    src: url("fonts/FuturaNowHeadlineExtraBlackItalic.ttf");
    font-style: italic;
    font-weight: 900;
}

:root {
    --verde: #2fa836;
    --nero: #000; /* #1d1d1b */
    --bianco: #fff;
    --blu: #28285c;
}
.bg-dark {
    background-color: #000 !important;
}
.text-dark {
    color: #000 !important;
}
body, html {
    color: var(--nero);
    width: 100vw;
    height: 100vh;
    max-width: 100%;
    padding: 0;
    margin: 0;
    font-family: "Archivo", sans-serif;
}
html {
    scroll-behavior: smooth;
    overflow: overlay;
    overflow-x: hidden;
}


h1, h2, h3, h4, h5, h6 {
    margin: 0;
    line-height: 1em;
    font-family: "Futura Now Headline", sans-serif;
}

h1, h2, h3 {
    text-transform: uppercase;
}

h3 {
    font-size: 2em;
}
p, div, ul, li, ol, a, button, input, textarea, span {
    font-family: "Archivo", sans-serif ;
}
a, a:hover {
    color: var(--nero);
    text-decoration: none;
}


.rounded10 {
    border-radius: 10px;
}

.bg-green {
    background-color: var(--verde);
}
.bg-blu {
    background-color: var(--blu);
}
.bg-nero {
    background-color: var(--nero);
}


.text-justify {
    text-align: justify;
    text-align-last: center;
}


/* DROPDOWN MENU */



/* SCROLLBAR */
::-webkit-scrollbar {
    width:10px;
    margin-right: 1em;
    z-index: 9999;
}
::-webkit-scrollbar-track {
    background:rgba(255, 255, 255, 0);
    border-color:rgba(255, 255, 255, 0);
}
::-webkit-scrollbar-thumb {
    background: var(--verde);
    opacity: 0.75;
    border-radius:10px;
    box-shadow: 0px 0px 5px 5px #00000040 inset;
    transition: 0.3s;
    cursor: pointer;
}

/* SELECTION */
::-moz-selection {
    background: #1a7220;
    color: #fff;
}
::selection {
    background: #1a7220;
    color: #fff;
}


.boxImgRealizzazioni {
    clip-path: polygon(0 30.00px,30.00px 0,calc(100% - 30.00px) 0,100% 30.00px,100% calc(100% - 30.00px),calc(100% - 30.00px) 100%,30.00px 100%,0 calc(100% - 30.00px));
    cursor: pointer;
    transition: 0.6s;
}
.boxImgRealizzazioni:hover {
    transform: scale(1.05);
    transition: 0.6s;
}
.imgRealizzazioni {
    clip-path: polygon(0 30.00px,30.00px 0,calc(100% - 30.00px) 0,100% 30.00px,100% calc(100% - 30.00px),calc(100% - 30.00px) 100%,30.00px 100%,0 calc(100% - 30.00px));
}
.imgsRealizzazioni {
    width: 100%;
    clip-path: polygon(0 30.00px,30.00px 0,calc(100% - 30.00px) 0,100% 30.00px,100% calc(100% - 30.00px),calc(100% - 30.00px) 100%,30.00px 100%,0 calc(100% - 30.00px));
}
.text-green {
    color: var(--verde);
}

.cardRealizzazione {
    position: relative;
    z-index: 5;
    cursor: pointer;
    transition: 0.7s;
    background-color: var(--verde);
    color: white;
    clip-path: polygon(0 50px,50px 0,calc(100% - 50px) 0,100% 50px,100% calc(100% - 50px),calc(100% - 50px) 100%,50px 100%,0 calc(100% - 50px));
}
.cardRealizzazione:hover {
    transition: 0.7s;
    transform: scale(0.98);
}
/*
.cardRealizzazione:before {
    content: "";
    position: absolute;
    inset: 0;
    z-index: 4;
    background-color: var(--verde);
    clip-path: polygon(0 50.00px,50.00px 0,calc(100% - 50.00px) 0,100% 50.00px,100% calc(100% - 50.00px),calc(100% - 50.00px) 100%,50.00px 100%,0 calc(100% - 50.00px),0 50.00px,10px  calc(50.00px + 4.14px),10px calc(100% - 50.00px - 4.14px),calc(50.00px + 4.14px) calc(100% - 10px),calc(100% - 50.00px - 4.14px) calc(100% - 10px),calc(100% - 10px) calc(100% - 50.00px - 4.14px),calc(100% - 10px) calc(50.00px + 4.14px),calc(100% - 50.00px - 4.14px) 10px,calc(50.00px + 4.14px) 10px,10px calc(50.00px + 4.14px));
}
 */
.imgCardRealizzazione {
    clip-path: polygon(0 50.00px,50.00px 0,calc(100% - 50.00px) 0,100% 50.00px,100% 100%,0 100%);
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 13em;
    z-index:-1;
}
.cardBorder4Corners {
    position: relative;
    z-index: 5;
    padding: 3em 2em;
}
.cardBorder4Corners:before {
    content: "";
    position: absolute;
    inset: 0;
    z-index: 4;
    background-color: var(--verde);
    clip-path: polygon(0 50.00px,50.00px 0,calc(100% - 50.00px) 0,100% 50.00px,100% calc(100% - 50.00px),calc(100% - 50.00px) 100%,50.00px 100%,0 calc(100% - 50.00px),0 50.00px,10px  calc(50.00px + 4.14px),10px calc(100% - 50.00px - 4.14px),calc(50.00px + 4.14px) calc(100% - 10px),calc(100% - 50.00px - 4.14px) calc(100% - 10px),calc(100% - 10px) calc(100% - 50.00px - 4.14px),calc(100% - 10px) calc(50.00px + 4.14px),calc(100% - 50.00px - 4.14px) 10px,calc(50.00px + 4.14px) 10px,10px calc(50.00px + 4.14px));
}

@media only screen and (max-width: 576px) {
    h1 {
        font-size: 3em;
        font-weight: 700;
    }
    h2 {
        font-size: 2em;
        font-weight: 500;
    }
    .boxFooter {
        --g: #000, #0000 0deg 180deg,#000 180deg;
        --mask:
                conic-gradient(from -135deg at top    20px left  20px,var(--g)) 0    0   /50% 100% no-repeat,
                conic-gradient(from -45deg at top    20px right 20px,var(--g)) 100% 0   /50% 100% no-repeat;
        -webkit-mask: var(--mask);
        mask: var(--mask);
    }
    .boxImgCover {
        --g: #000, #0000 0deg 180deg,#000 180deg;
        --mask:
                conic-gradient(from 45deg at bottom 20px right 20px,var(--g)) 100% 100%/50% 100% no-repeat,
                conic-gradient(from -225deg at bottom 20px left  20px,var(--g)) 0    100%/50% 100% no-repeat;
        -webkit-mask: var(--mask);
        mask: var(--mask);
    }
    .img4corners {
        width: 60vw;
        clip-path: polygon(0 20.00px,20.00px 0,calc(100% - 20.00px) 0,100% 20.00px,100% calc(100% - 20.00px),calc(100% - 20.00px) 100%,20.00px 100%,0 calc(100% - 20.00px));
    }
    .imgColResp {
        width: 60vw;
    }
    .imgColRespSmall {
        width: 60vw;
    }
    .rtl {
        direction: ltr;
    }
    .imgRealizzazioni {
        width: 90vw;
        height: 20em;
    }
    .imgBig {
        transform: none;
    }
}
@media only screen and (min-width: 576px) {
    h1 {
        font-size: 3em;
        font-weight: 700;
    }
    h2 {
        font-size: 2em;
        font-weight: 500;
    }
    .boxFooter {
        --g: #000, #0000 0deg 180deg,#000 180deg;
        --mask:
                conic-gradient(from -135deg at top    20px left  20px,var(--g)) 0    0   /50% 100% no-repeat,
                conic-gradient(from -45deg at top    20px right 20px,var(--g)) 100% 0   /50% 100% no-repeat;
        -webkit-mask: var(--mask);
        mask: var(--mask);
    }
    .boxImgCover {
        --g: #000, #0000 0deg 180deg,#000 180deg;
        --mask:
                conic-gradient(from 45deg at bottom 20px right 20px,var(--g)) 100% 100%/50% 100% no-repeat,
                conic-gradient(from -225deg at bottom 20px left  20px,var(--g)) 0    100%/50% 100% no-repeat;
        -webkit-mask: var(--mask);
        mask: var(--mask);
    }
    .img4corners {
        width: 60vw;
        clip-path: polygon(0 30.00px,30.00px 0,calc(100% - 30.00px) 0,100% 30.00px,100% calc(100% - 30.00px),calc(100% - 30.00px) 100%,30.00px 100%,0 calc(100% - 30.00px));
    }
    .imgColResp {
        width: 60vw;
    }
    .imgColRespSmall {
        width: 60vw;
    }
    .rtl {
        direction: ltr;
    }
    .imgRealizzazioni {
        width: 80vw;
        height: 20em;
    }
    .imgBig {
        transform: none;
    }
}
@media only screen and (min-width: 768px) {
    h1 {
        font-size: 3.5em;
        font-weight: 700;
    }
    h2 {
        font-size: 2.5em;
        font-weight: 500;
    }
    .boxFooter {
        --g: #000, #0000 0deg 180deg,#000 180deg;
        --mask:
                conic-gradient(from -135deg at top    30px left  30px,var(--g)) 0    0   /50% 100% no-repeat,
                conic-gradient(from -45deg at top    30px right 30px,var(--g)) 100% 0   /50% 100% no-repeat;
        -webkit-mask: var(--mask);
        mask: var(--mask);
    }
    .boxImgCover {
        --g: #000, #0000 0deg 180deg,#000 180deg;
        --mask:
                conic-gradient(from 45deg at bottom 30px right 30px,var(--g)) 100% 100%/50% 100% no-repeat,
                conic-gradient(from -225deg at bottom 30px left  30px,var(--g)) 0    100%/50% 100% no-repeat;
        -webkit-mask: var(--mask);
        mask: var(--mask);
    }
    .img4corners {
        width: 40vw;
        clip-path: polygon(0 40.00px,40.00px 0,calc(100% - 40.00px) 0,100% 40.00px,100% calc(100% - 40.00px),calc(100% - 40.00px) 100%,40.00px 100%,0 calc(100% - 40.00px));
    }
    .imgColResp {
        width: 40vw;
    }
    .imgColRespSmall {
        width: 20vw;
    }
    .rtl {
        direction: ltr;
    }
    .imgRealizzazioni {
        width: 30em;
        height: 20em;
    }
    .imgBig {
        transform: none;
    }
}
@media only screen and (min-width: 992px) {
    h1 {
        font-size: 4em;
        font-weight: 700;
    }
    h2 {
        font-size: 3em;
        font-weight: 500;
    }
    .boxFooter {
        --g: #000, #0000 0deg 180deg,#000 180deg;
        --mask:
                conic-gradient(from -135deg at top    40px left  40px,var(--g)) 0    0   /50% 100% no-repeat,
                conic-gradient(from -45deg at top    40px right 40px,var(--g)) 100% 0   /50% 100% no-repeat;
        -webkit-mask: var(--mask);
        mask: var(--mask);
    }
    .boxImgCover {
        --g: #000, #0000 0deg 180deg,#000 180deg;
        --mask:
                conic-gradient(from 45deg at bottom 40px right 40px,var(--g)) 100% 100%/50% 100% no-repeat,
                conic-gradient(from -225deg at bottom 40px left  40px,var(--g)) 0    100%/50% 100% no-repeat;
        -webkit-mask: var(--mask);
        mask: var(--mask);
    }
    .img4corners {
        width: 25vw;
        clip-path: polygon(0 40.00px,40.00px 0,calc(100% - 40.00px) 0,100% 40.00px,100% calc(100% - 40.00px),calc(100% - 40.00px) 100%,40.00px 100%,0 calc(100% - 40.00px));
    }
    .imgColResp {
        width: 25vw;
    }
    .imgColRespSmall {
        width: 20vw;
    }
    .rtl {
        direction: rtl;
    }
    .imgRealizzazioni {
        width: 40vw;
        height: 40vh;
    }
    .imgBig {
        transform: scale(1.25);
    }
}
@media only screen and (min-width: 1200px) {
    h1 {
        font-size: 4em;
        font-weight: 700;
    }
    h2 {
        font-size: 3em;
        font-weight: 500;
    }
    .boxFooter {
        --g: #000, #0000 0deg 180deg,#000 180deg;
        --mask:
                conic-gradient(from -135deg at top    40px left  40px,var(--g)) 0    0   /50% 100% no-repeat,
                conic-gradient(from -45deg at top    40px right 40px,var(--g)) 100% 0   /50% 100% no-repeat;
        -webkit-mask: var(--mask);
        mask: var(--mask);
    }
    .boxImgCover {
        --g: #000, #0000 0deg 180deg,#000 180deg;
        --mask:
                conic-gradient(from 45deg at bottom 40px right 40px,var(--g)) 100% 100%/50% 100% no-repeat,
                conic-gradient(from -225deg at bottom 40px left  40px,var(--g)) 0    100%/50% 100% no-repeat;
        -webkit-mask: var(--mask);
        mask: var(--mask);
    }

    .img4corners {
        width: 20vw;
        clip-path: polygon(0 50.00px,50.00px 0,calc(100% - 50.00px) 0,100% 50.00px,100% calc(100% - 50.00px),calc(100% - 50.00px) 100%,50.00px 100%,0 calc(100% - 50.00px));
    }
    .imgColResp {
        width: 25vw;
    }
    .imgColRespSmall {
        width: 20vw;
    }
    .rtl {
        direction: rtl;
    }
    .imgRealizzazioni {
        width: 40vw;
        height: 40vh;
    }
    .imgBig {
        transform: scale(1.4);
    }
}

@media only screen and (min-width: 1500px) {
    .imgBig {
        transform: scale(1.1);
    }
}
