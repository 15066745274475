
#areariservata html, body {
    background-color: #eaeef3;
    color: #000;
}

#areariservata h1, h2, h3, h4, h5, h6, p, a, div, span, body, li, em, a {
    margin: 0;
}

#areariservata a {
    text-decoration: none;
}

#areariservata .App {
    background-color: #eaeef3;
    min-height: 100vh;
    color: #3a4064;
}

#areariservata .sidebarDesktop {
    height: 100vh;
    background-color: #fff;
    color: #444;
    position: sticky;
    left: 0;
    top: 0;
    z-index: 99;
    overflow-x: hidden;
}

#areariservata .sidebarMobile {

}

#areariservata .linkSidebar {
    transition: 0.3s;
    cursor: pointer;
}
#areariservata .linkSidebar a {
    text-decoration: none;
    color: #444;
    transition: 0.3s;
}
#areariservata .linkSidebar:hover, .linkSidebar:hover a, .linkSidebarActive {
    background-color: #f4f6f9;
    color: #4680ff;
    transition: 0.3s;
}

#areariservata .iconHover {
    transition: 0.3s;
    cursor: pointer;
    opacity: 1;
}
#areariservata .iconHover:hover {
    transition: 0.3s;
    opacity: 0.85;
    transform: scale(0.9);
}

#areariservata .rounded10 {
    border-radius: 10px;
}

@media only screen and (max-width: 576px) {

}
@media only screen and (min-width: 576px) {

}
@media only screen and (min-width: 768px) {

}
@media only screen and (min-width: 992px) {

}
@media only screen and (min-width: 1200px) {

}
